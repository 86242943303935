import React from "react";

function HeroBanner() {
  return (
    <>
      <div className="hero-area hero-style-one">
        <div className="hero-main-wrapper position-relative h-100">
          <div className="swiper-wrapper">
            <div className="slider-bg-1">
              <div className="container">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-xl-8 col-lg-10"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="hero-one-pagination d-flex justify-content-center flex-column align-items-center gap-3" />
        </div>
      </div>
    </>
  );
}

export default HeroBanner;
