import React from "react";

function UpCommingHome1() {
  return (
    <>
      <div className="upcoming-seciton">
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg"
        />
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
              <div className="section-title1">
                <h2>رؤيتنا</h2>
                <p className="mb-0">
                  ان تكون أنظمتنا الخيار الأول للجهات الحكومية والجهات الخاصة
                  والأفراد ، للاستفادة من خدمة المزادات الإلكترونية الزمنية
                  والمنقولة نقل مباشر وأيضاً مزادات الظرف المغلق.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="upcoming-seciton">
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg"
        />
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
              <div className="section-title1">
                <h2>رسالتنا</h2>
                <p className="mb-0">
                  المساهمة في نمو أعمال عملائنا من خلال منصتنا، التي تقدم حلول
                  فعالة وخدمات على مدار 24 ساعة، لخلق قيمة تنافسية بثقة عالية
                  بين البائع والمشتري.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpCommingHome1;
