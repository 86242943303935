import React from "react";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Link } from "react-router-dom";
SwiperCore.use([Navigation, Autoplay]);
function TestimonialHome1() {
  const testimonialSlider = {
    slidesPerView: 1,
    speed: 1000,
    spaceBetween: 24,
    loop: true,
    roundLengths: true,
    navigation: {
      nextEl: ".testi-prev1",
      prevEl: ".testi-next1",
    },

    breakpoints: {
      280: {
        slidesPerView: 1,
      },
      480: {
        slidesPerView: 1,
        autoplay: true,
      },
      768: {
        slidesPerView: 1,
      },
      992: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3,
      },
    },
  };
  return (
    <>
      <div className="testimonial-section pt-80 pb-80">
        <img
          alt="testimonial-images"
          src={process.env.PUBLIC_URL + "/images/bg/client-right.png"}
          className="client-right-vector"
        />
        <img
          alt="testimonial-images"
          src={process.env.PUBLIC_URL + "/images/bg/client-left.png"}
          className="client-left-vector"
        />
        <img
          alt="testimonial-images"
          src={process.env.PUBLIC_URL + "/images/bg/clent-circle1.png"}
          className="client-circle1"
        />
        <img
          alt="testimonial-images"
          src={process.env.PUBLIC_URL + "/images/bg/clent-circle2.png"}
          className="client-circle2"
        />
        <img
          alt="testimonial-images"
          src={process.env.PUBLIC_URL + "/images/bg/clent-circle3.png"}
          className="client-circle3"
        />
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
              <div className="section-title1">
                <h2>خدماتنا</h2>
                <p className="mb-0">
                  شركة مباشر لتصميم البرامج شركة سعودية 100% مرخصة ومعتمدة، ومن
                  أنظمتها منصة مباشر المتخصصة بإدارة وتنظيم وتشغيل المزادات
                  الإلكترونية بطرق بيع مختلفة ومنها ما يلي:
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center position-relative">
            <Swiper
              {...testimonialSlider}
              className="swiper testimonial-slider"
            >
              <div className="swiper-wrapper">
                <SwiperSlide className="swiper-slide">
                  <div
                    className="testimonial-single hover-border1 wow fadeInDown"
                    data-wow-duration="1.5s"
                    data-wow-delay=".2s"
                  >
                    <img
                      alt="testimonial-images"
                      src={
                        process.env.PUBLIC_URL + "/images/icons/quote-green.svg"
                      }
                      className="quote-icon"
                    />
                    <div className="testi-img custom-img">
                      <img
                        alt="testimonial-images"
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/live-auction.svg"
                        }
                      />
                    </div>
                    <div className="testi-content">
                      <div className="testi-designation">
                        <h5 className="mb-4">
                          <Link to={"#"}>المزاد المباشر (الهجين)</Link>
                        </h5>
                      </div>
                      <p className="para">
                        - نقل مباشر للمزاد من أرض الحدث (صوت وصورة).
                      </p>
                      <p className="para">
                        - الربط بين المزايدة الحالية والمزايدة الألية.
                      </p>
                      <p className="para">
                        - النظام يدعم عدد لامحدود من المزايدين.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div
                    className="testimonial-single hover-border1 wow fadeInDown"
                    data-wow-duration="1.5s"
                    data-wow-delay=".4s"
                  >
                    <img
                      alt="testimonial-images"
                      src={
                        process.env.PUBLIC_URL + "/images/icons/quote-green.svg"
                      }
                      className="quote-icon"
                    />
                    <div className="testi-img custom-img">
                      <img
                        alt="testimonial-images"
                        src={
                          process.env.PUBLIC_URL +
                          "/images/bg/timed-auction.png"
                        }
                      />
                    </div>
                    <div className="testi-content">
                      <div className="testi-designation">
                        <h5 className="mb-4">
                          <Link to={"#"}>المزاد الزمني</Link>
                        </h5>
                      </div>
                      <p className="para">
                        - عرض المزاد بمدة زمنية محددة "يتبع العد التنازلي".
                      </p>
                      <p className="para">- نظام المزايدة الألية"autobid".</p>
                      <p className="para">
                        - النظام يدعم عدد لامحدود من المزايدين.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div
                    className="testimonial-single hover-border1 wow fadeInDown"
                    data-wow-duration="1.5s"
                    data-wow-delay=".4s"
                  >
                    <img
                      alt="testimonial-images"
                      src={
                        process.env.PUBLIC_URL + "/images/icons/quote-green.svg"
                      }
                      className="quote-icon"
                    />
                    <div className="testi-img custom-img">
                      <img
                        alt="testimonial-images"
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/mail-security.svg"
                        }
                      />
                    </div>
                    <div className="testi-content">
                      <div className="testi-designation">
                        <h5 className="mb-4">
                          <Link to={"#"}>الظرف المغلق</Link>
                        </h5>
                      </div>
                      <p className="para">
                        {" "}
                        - مزادات يتم فيها تشفير عروض المزايدة بتقنية (Peer to
                        Peer Encryption).
                      </p>
                      <p className="para">
                        - لا يتم فك التشفير الا بكود مع المشتري في الوقت المحدد
                        لفتح المظاريف.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide className="swiper-slide">
                  <div
                    className="testimonial-single hover-border1 wow fadeInDown"
                    data-wow-duration="1.5s"
                    data-wow-delay=".4s"
                  >
                    <img
                      alt="testimonial-images"
                      src={
                        process.env.PUBLIC_URL + "/images/icons/quote-green.svg"
                      }
                      className="quote-icon"
                    />
                    <div className="testi-img custom-img">
                      <img
                        alt="testimonial-images"
                        src={process.env.PUBLIC_URL + "/images/icons/sale.svg"}
                      />
                    </div>
                    <div className="testi-content">
                      <div className="testi-designation">
                        <h5 className="mb-4">
                          <Link to={"#"}> البيع المباشر</Link>
                        </h5>
                      </div>
                      <p className="para">
                        {" "}
                        - عرض المنتج في المنصة للبيع المباشر بحيث يتم وضع سعر
                        البيع دون مزايدات.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              </div>
            </Swiper>
            <div className="slider-arrows testimonial2-arrow d-flex justify-content-between gap-3">
              <div
                className="testi-prev1 swiper-prev-arrow"
                tabIndex={0}
                role="button"
                aria-label="Previous slide"
              >
                <i className="bi bi-arrow-left" />
              </div>
              <div
                className="testi-next1 swiper-next-arrow"
                tabIndex={0}
                role="button"
                aria-label="Next slide"
              >
                <i className="bi bi-arrow-right" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TestimonialHome1;
