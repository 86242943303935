import React from "react";
import CategoryHome2 from "../homepage2/CategoryHome2";
function SponsorHome1() {
  return (
    <>
      <div className="sponsor-section style-1 pb-4">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
              <div className="section-title1">
                <h2>شركاء النجاح</h2>
                <p className="mb-0">
                  نعلم تماماً ونقدر أن بناء العلاقات مع العملاء هو أساس النجاح
                  للتقدم والازدهار ونؤمن بأن العميل هو حجر الأساس.
                </p>
              </div>
            </div>
            <CategoryHome2 />
          </div>
        </div>
      </div>
    </>
  );
}

export default SponsorHome1;
