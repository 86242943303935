import React from "react";

export default function Commercial() {
  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <img
              src="https://mobasher-v1-upload.s3.ap-south-1.amazonaws.com/shrkt_mbashr_lltsmym_3_1_1bab4d59c8.png"
              alt="commercial register"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </>
  );
}
