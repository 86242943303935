import React from "react";
import { Link } from "react-router-dom";

function WhoWeAreArea() {
  return (
    <>
      <div className="about-section pt-120 pb-120 overflow-hidden">
        <img
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-top"
          alt="section-bg"
        />
        <div className="container">
          <div className="row d-flex justify-content-center g-4">
            <div className="col-lg-6 col-md-10">
              <div className="about-img-area">
                <img
                  src={process.env.PUBLIC_URL + "/images/bg/about-img.png"}
                  className="img-fluid about-img wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay=".2s"
                  alt="about-img"
                />

                <img
                  src={process.env.PUBLIC_URL + "/images/bg/about-vector.png"}
                  className="img-fluid about-vector"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-10">
              <div
                className="about-content wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <h2>نسعى لتطوير المزادات إلكترونياً وتحقيق رؤية 2030</h2>
                <p className="para">
                  منصة مباشر هي لإدارة وتنظيم وتشغيل المزادات إلكترونياً وتعمل
                  كحلقة وصل بين البائعين والمشترين، من خلال المزادات العلنية
                  المنقولة نقل مباشر والمزادات الزمنية الإلكترونية دون الحاجة
                  للحضور إلى مقر المزاد، وتمكن البائعين من كافة الفئات من القطاع
                  الحكومي والقطاع الخاص والأفراد من عرض وبيع ما لديهم من عروض
                  وسلع بأنواعها المختلفة، والسماح للراغبين بالشراء بالمزايدة
                  بسهولة وبحلول تقنية متطورة وآمنة وبخدمات متميزة وبشفافية
                  عالية.
                </p>

                <h2>مزايا مزادات مباشر الإلكترونية :</h2>
                <ul className="about-list">
                  <li>
                    <Link to={"#"}>
                      سهولة المشاركة دون التقيد بالموقع الجغرافي.
                    </Link>
                  </li>
                  <li>
                    <Link to={"#"}>مرتبطة بقنوات الدفع الإلكتروني.</Link>
                  </li>
                  <li>
                    <Link to={"#"}>
                      أمنة تدعم التحقق من المشتري عبر نظام نافذ و منصة نفاذ.
                    </Link>
                  </li>
                  <li>
                    <Link to={"#"}>
                      نظام يدعم استقبال أرقام ضخمة من المزايدات في ذات الوقت.
                    </Link>
                  </li>
                  <li>
                    <Link to={"#"}>
                      قبول المزايدة في وقت قياسي "أقل من أجزاء الثانية".
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhoWeAreArea;
