import React, { useEffect } from "react";
import WOW from "wowjs";
import "react-toastify/dist/ReactToastify.css";
import Modal from "./Modal";
import Privacy from "../content/Privacy";
import Usage from "../content/Usage";
import License from "../content/License";
import Commercial from "../content/Commercial";
import Return from "../content/Return";

const d = new Date();
const year = d.getFullYear();
const modalIds = {
  realEstate: "realEstate",
  usage: "usage",
  privacy: "privacy",
  commerciaRegister: "commercialRegister",
  return: "returnPolicy",
};

function Footer(props) {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  return (
    <>
      <Modal id={modalIds.realEstate} title="التراخيص">
        <License />
      </Modal>
      <Modal id={modalIds.usage} title="شروط الإستخدام وإخلاء المسئولية">
        <Usage />
      </Modal>
      <Modal
        id={modalIds.privacy}
        title="سياسة الخصوصية وسرية المعلومات
"
      >
        <Privacy />
      </Modal>
      <Modal id={modalIds.commerciaRegister} title="السجل التجاري">
        <Commercial />
      </Modal>
      <Modal id={modalIds.return} title="سياسة الاسترجاع">
        <Return />
      </Modal>
      <footer>
        <div className="footer-top">
          <div className="container">
            <div className="row gap-md-0 gap-5">
              <div className="col-md-12">
                <div className="col-12">
                  <div className="top-wrapper d-flex justify-content-center align-items-center">
                    <p
                      className="modal-trigger me-3"
                      data-bs-toggle="modal"
                      data-bs-target={`#${modalIds.realEstate}`}
                    >
                      مرخص من{" "}
                      <img
                        src={
                          process.env.PUBLIC_URL + "/real-estate-authority.svg"
                        }
                        alt="شعار الهيئة العامة للعقار"
                      />
                    </p>
                    <p>رقم الترخيص: 4200000206</p>
                  </div>
                  <div className="bottom-wrapper mb-5">
                    <div className="d-flex mx-auto justify-content-between">
                      <p
                        className="modal-trigger"
                        data-bs-toggle="modal"
                        data-bs-target={`#${modalIds.usage}`}
                      >
                        شروط الإستخدام وإخلاء المسئولية
                      </p>
                      <div className="divider" />
                      <p
                        className="modal-trigger"
                        data-bs-toggle="modal"
                        data-bs-target={`#${modalIds.privacy}`}
                      >
                        سياسة الخصوصية وسرية المعلومات
                      </p>
                      <div className="divider" />
                      <p
                        className="modal-trigger"
                        data-bs-toggle="modal"
                        data-bs-target={`#${modalIds.return}`}
                      >
                        سياسة الاسترجاع
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mb-5">
                  {/* Should add modals when documents are available */}
                  <p className="m-0">الإقرار الضريبي</p>
                  <div className="divider" />

                  <p
                    className="modal-trigger m-0"
                    data-bs-toggle="modal"
                    data-bs-target={`#${modalIds.commerciaRegister}`}
                  >
                    السجل التجاري
                  </p>
                  <div className="divider" />

                  <a
                    className="m-0 modal-trigger"
                    href="/tax-authority-cerificate.pdf"
                    download
                  >
                    هيئة الزكاة والضريبة والجمارك{" "}
                  </a>
                </div>

                <div className="d-md-block d-none text-center mt-4">
                  <span>
                    جميع الحقوق محفوظة لمباشر {year}
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/icons/mobasher.svg"
                      }
                      alt="شعار مباشر"
                      className="ms-2"
                    />
                  </span>
                </div>
              </div>
              {/* <div className="col-md-4 d-flex gap-3 flex-column">
                <h3 className="fs-4">معلومات التواصل</h3>
                <div>
                  <iframe
                    title="main-hq"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1810.7017220063549!2d46.68315333866783!3d24.815873044489596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2ee3a4155aa9bf%3A0x8aec4aa3f3900f6!2z2YXYqNin2LTYsQ!5e0!3m2!1sen!2ssa!4v1713348213455!5m2!1sen!2ssa"
                    style={{ border: 0, width: "100%" }}
                    allowFullScreen={true}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                  <p>الموقع: طريق الثمامة، حي الندى، مدينة الرياض 13317</p>
                </div>
                <a href="mailto:Mobasher@example.com">
                  <img
                    alt="email"
                    src={process.env.PUBLIC_URL + "/images/icons/email.svg"}
                    className="me-1"
                  />
                  support@mobasher.sa
                </a>
                <div>
                  <img
                    alt="call"
                    src={process.env.PUBLIC_URL + "/images/icons/call.svg"}
                    className="me-1"
                  />
                  خدمة العملاء: 920023225
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
