import React from "react";

function LiveAuctionHome1() {
  return (
    <>
      <div className="live-auction pb-20">
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg"
        />
        <div className="container position-relative">
          <img
            alt="images"
            src={process.env.PUBLIC_URL + "/images/bg/dotted1.png"}
            className="dotted1"
          />
          <img
            alt="images"
            src={process.env.PUBLIC_URL + "/images/bg/dotted1.png"}
            className="dotted2"
          />
          <div className="row d-flex justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
              <div className="section-title1">
                <h2>من نحن ؟</h2>
                <p className="mb-0">
                  شركة تقنية متخصصة في إقامة المزادات العقارية والمركبات والسلع
                  الأخرى إلكترونياً.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LiveAuctionHome1;
