import React from "react";
import AboutUsCounter from "../../common/AboutUsCounter";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import Catagory from "./Catagory";
import HeroBanner from "./HeroBanner";
import LiveAuctionHome1 from "./LiveAuctionHome1";
import SponsorHome1 from "./SponsorHome1";
import TestimonialHome1 from "./TestimonialHome1";
import UpCommingHome1 from "./UpCommingHome1";
import WhoWeAreArea from "../about/WhoWeAreArea";

function HomePageOne() {
  return (
    <>
      <Header />
      <HeroBanner />
      <Catagory />
      <WhoWeAreArea />
      <LiveAuctionHome1 />
      <UpCommingHome1 />
      <TestimonialHome1 />
      <SponsorHome1 />
      {/* <AboutUsCounter /> */}
      <Footer />
    </>
  );
}

export default HomePageOne;
