import React from "react";

import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Autoplay]);

function CategoryHome2() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const categorySlider = {
    slidesPerView: 1,
    speed: 1000,
    spaceBetween: 30,
    loop: true,
    autoplay: true,
    roundLengths: true,
    pagination: false,
    navigation: {
      nextEl: ".category-prev2",
      prevEl: ".category-next2",
    },

    breakpoints: {
      280: {
        slidesPerView: 1,
      },
      380: {
        slidesPerView: 2,
      },
      540: {
        slidesPerView: 3,
      },
      576: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 5,
      },
      1200: {
        slidesPerView: 6,
      },
      1400: {
        slidesPerView: 7,
      },
    },
  };
  return (
    <>
      <div className="category-section2  pb-120" id="category">
        <div className="container position-relative">
          <div className="row">
            <div className="col-12">
              <Swiper {...categorySlider} className="swiper category2-slider">
                <div className="swiper-wrapper">
                  <SwiperSlide className="swiper-slide">
                    <div
                      className="eg-card category-card2 fadeInDown"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.2s"
                    >
                      <img
                        alt="categoryImage"
                        src="https://mobasher-v1-upload.s3.ap-south-1.amazonaws.com/logo_907232d427.jpg"
                        style={{ width: "inherit" }}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div
                      className="eg-card category-card2 wow fadeInDown"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.2s"
                    >
                      <img
                        alt="categoryImage"
                        src="https://mobasher-v1-upload.s3.ap-south-1.amazonaws.com/amaken_d8fdb7f240.png"
                        style={{ width: "inherit" }}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div
                      className="eg-card category-card2 wow fadeInDown"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.4s"
                    >
                      <img
                        alt="categoryImage"
                        src="https://mobasher-v1-upload.s3.ap-south-1.amazonaws.com/mahafz_1dfdec0e73.png"
                        style={{ width: "inherit" }}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div
                      className="eg-card category-card2 wow fadeInDown"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.4s"
                    >
                      <img
                        alt="categoryImage"
                        src="https://mobasher-v1-upload.s3.ap-south-1.amazonaws.com/sfwt_alahsan_8999594c98.png"
                        style={{ width: "inherit" }}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div
                      className="eg-card category-card2 wow fadeInDown"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.4s"
                    >
                      <img
                        alt="categoryImage"
                        src="https://mobasher-v1-upload.s3.ap-south-1.amazonaws.com/altharwa_e6bc37c8cd.png"
                        style={{ width: "inherit" }}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div
                      className="eg-card category-card2 wow fadeInDown"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.4s"
                    >
                      <img
                        alt="categoryImage"
                        src="https://mobasher-v1-upload.s3.ap-south-1.amazonaws.com/u3dmc_Kiw_400x400_b72e6f5d92.jpg"
                        style={{ width: "inherit" }}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div
                      className="eg-card category-card2 wow fadeInDown"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.4s"
                    >
                      <img
                        alt="categoryImage"
                        src="https://mobasher-v1-upload.s3.ap-south-1.amazonaws.com/sar_b685540ecc.jpg"
                        style={{ width: "inherit" }}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div
                      className="eg-card category-card2 wow fadeInDown"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.4s"
                    >
                      <img
                        alt="categoryImage"
                        src="https://mobasher-v1-upload.s3.ap-south-1.amazonaws.com/budget_07dbd2c05e.jpg"
                        style={{ width: "inherit" }}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div
                      className="eg-card category-card2 wow fadeInDown"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.4s"
                    >
                      <img
                        alt="categoryImage"
                        src="https://mobasher-v1-upload.s3.ap-south-1.amazonaws.com/smasco_257ce90930.jpg"
                        style={{ width: "inherit" }}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div
                      className="eg-card category-card2 wow fadeInDown"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.4s"
                    >
                      <img
                        alt="categoryImage"
                        src="https://mobasher-v1-upload.s3.ap-south-1.amazonaws.com/choices_solution_4d333a2e3d.jpg"
                        style={{ width: "inherit" }}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div
                      className="eg-card category-card2 wow fadeInDown"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.4s"
                    >
                      <img
                        alt="categoryImage"
                        src="https://mobasher-v1-upload.s3.ap-south-1.amazonaws.com/infath_400_1_c92187b939.jpg"
                        style={{ width: "inherit" }}
                      />
                    </div>
                  </SwiperSlide>
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoryHome2;
